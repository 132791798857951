import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel,
  ErrorText,
} from "./file-upload.styles";

const KILO_BYTES_PER_BYTE = 1000;
const MAX_FILE_SIZE_IN_BYTES = 5000000000000; // 5000000 = 5MB

const convertNestedObjectToArray = (nestedObj) =>
  Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const useFileHandler = (files, setFiles, updateFilesCb, maxFileSizeInBytes) => {
  const [error, setError] = useState("");

  const addNewFiles = (newFiles) => {
    const updatedFiles = { ...files };
    for (const file of newFiles) {
      if (file.size > maxFileSizeInBytes) {
        setError(`Le fichier ${file.name} dépasse la taille maximale de 5MB`);
        continue;
      }
      updatedFiles[file.name] = file;
    }
    return updatedFiles;
  };

  const callUpdateFilesCb = (files) => {
    const filesAsArray = convertNestedObjectToArray(files);
    updateFilesCb(filesAsArray);
  };

  const handleNewFileUpload = (e) => {
    const { files: newFiles } = e.target;
    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
      setError(""); // Clear error after successful upload
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const { files: newFiles } = e.dataTransfer;
    if (newFiles.length) {
      const updatedFiles = addNewFiles(newFiles);
      setFiles(updatedFiles);
      callUpdateFilesCb(updatedFiles);
      setError(""); // Clear error after successful upload
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const removeFile = (fileName) => {
    const updatedFiles = { ...files };
    delete updatedFiles[fileName];
    setFiles(updatedFiles);
    callUpdateFilesCb(updatedFiles);
  };

  return {
    error,
    handleNewFileUpload,
    handleDrop,
    handleDragOver,
    removeFile,
  };
};

const FileUpload = ({
  label,
  updateFilesCb,
  files,
  setFiles,
  maxFileSizeInBytes = MAX_FILE_SIZE_IN_BYTES,
  ...otherProps
}) => {
  const fileInputField = useRef(null);

  const { error, handleNewFileUpload, handleDrop, handleDragOver, removeFile } =
    useFileHandler(files, setFiles, updateFilesCb, maxFileSizeInBytes);

  const handleUploadBtnClick = () => {
    fileInputField.current.click();
  };

  return (
    <>
      <FileUploadContainer onDrop={handleDrop} onDragOver={handleDragOver}>
        <InputLabel>{label}</InputLabel>
        <DragDropText>Cliquer ou déposer vos fichiers ici</DragDropText>
        <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
          <i className="fas fa-file-upload" />
          <span> Ouvrir</span>
        </UploadFileBtn>
        <FormField
          type="file"
          ref={fileInputField}
          onChange={handleNewFileUpload}
          title=""
          value=""
          {...otherProps}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </FileUploadContainer>
      <FilePreviewContainer>
        <span>Éléments ajoutés</span>
        <PreviewList>
          {Object.keys(files).map((fileName, index) => {
            const file = files[fileName];
            const isImageFile = file.type.startsWith("image/");
            return (
              <PreviewContainer
                key={fileName}
                onClick={() => removeFile(fileName)}
              >
                <div>
                  {isImageFile && (
                    <ImagePreview
                      src={URL.createObjectURL(file)}
                      alt={`file preview ${index}`}
                    />
                  )}
                  <FileMetaData isImageFile={isImageFile}>
                    <span>{file.name}</span>
                    <aside>
                      <span>{convertBytesToKB(file.size)} kb</span>
                      <RemoveFileIcon
                        className="fas fa-trash-alt"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeFile(fileName);
                        }}
                        aria-label={`Supprimer ${file.name}`}
                      />
                    </aside>
                  </FileMetaData>
                </div>
              </PreviewContainer>
            );
          })}
        </PreviewList>
      </FilePreviewContainer>
    </>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  updateFilesCb: PropTypes.func.isRequired,
  files: PropTypes.object.isRequired, // Assurez-vous que files est un objet
  setFiles: PropTypes.func.isRequired,
  maxFileSizeInBytes: PropTypes.number,
  multiple: PropTypes.bool,
};

export default React.memo(FileUpload);
