import React, { useState, useCallback } from "react";
import { Button, Modal, ProgressBar } from "react-bootstrap";
import axios from "axios";
import FileUpload from "../file-upload.component";
import PropTypes from "prop-types";

const usePresignedUrl = (currentFolder) => {
  const getPresignedUrl = useCallback(
    async (fileName, fileType) => {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_ENDPOINT}/generate-presigned-url/`,
        data: {
          file_name: fileName,
          file_type: fileType,
          current_folder: currentFolder,
        },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.REACT_TOKEN_AUTH_KEY).accessToken
          }`,
        },
      };

      try {
        const { data } = await axios(config);
        return data;
      } catch (error) {
        console.error("Error fetching presigned URL:", error);
        throw error;
      }
    },
    [currentFolder]
  );

  return { getPresignedUrl };
};

const useFileUpload = (currentFolder, setLoading, setUploadComplete) => {
  const { getPresignedUrl } = usePresignedUrl(currentFolder);

  const uploadFileOnServer = useCallback(
    async (url, file, index, list, setNowLoading, setListUploadFile) => {
      return axios.put(url, file, {
        headers: { "Content-Type": file.type },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const totalLength = progressEvent.total;
            const progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );

            setNowLoading(true);
            const updatedList = { ...list };
            updatedList[index].progress = progress;
            setListUploadFile(updatedList);
          }
        },
      });
    },
    []
  );

  const confirmFiles = useCallback(
    async (id) => {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_ENDPOINT}/confirm_files/`,
        data: { id },
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage.REACT_TOKEN_AUTH_KEY).accessToken
          }`,
        },
      };

      try {
        await axios(config);
        setLoading(true);
      } catch (error) {
        console.error("Error confirming files:", error);
        throw error;
      }
    },
    [setLoading]
  );

  const uploadFiles = async (files, setNowLoading, setListUploadFile) => {
    const filesArray = Object.entries(files);
    const list = filesArray.map(([, value], index) => ({
      name: `${index + 1}/${filesArray.length} ${value.name}`,
      progress: 0,
    }));
    setListUploadFile(list);

    for (let i = 0; i < filesArray.length; i++) {
      const [, file] = filesArray[i];
      try {
        const { url, id } = await getPresignedUrl(file.name, file.type);
        if (url && id) {
          await uploadFileOnServer(
            url,
            file,
            i,
            list,
            setNowLoading,
            setListUploadFile
          );
          await confirmFiles(id);

          // Check if this is the last file
          if (i === filesArray.length - 1) {
            setUploadComplete(true);
          }
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        setNowLoading(false);
      }
    }
  };

  return { uploadFiles };
};

const DropModal = ({ showModal, setShowModal, currentFolder, setLoading }) => {
  const [files, setFiles] = useState({});
  const [listUploadFile, setListUploadFile] = useState({});
  const [nowLoading, setNowLoading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const { uploadFiles } = useFileUpload(
    currentFolder,
    setLoading,
    setUploadComplete
  );

  const handleClose = () => setShowModal(false);

  const saveFiles = useCallback(async () => {
    setNowLoading(true);
    await uploadFiles(files, setNowLoading, setListUploadFile);
    setNowLoading(false);
  }, [files, uploadFiles]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const updateUploadedFiles = (newFiles) => setFiles(newFiles);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Déposer des fichiers</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {nowLoading ? (
          <MultipleLoading listUploadFile={listUploadFile} />
        ) : (
          <div>
            {!uploadComplete ? (
              <form onSubmit={handleSubmit}>
                <FileUpload
                  accept="*"
                  label="Profile Image(s)"
                  multiple
                  files={files}
                  setFiles={setFiles}
                  updateFilesCb={updateUploadedFiles}
                />
              </form>
            ) : (
              <p>Téléchargement terminé</p>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!nowLoading && (
          <>
            <Button variant="secondary" onClick={handleClose}>
              Fermer
            </Button>
            {!uploadComplete && (
              <Button variant="primary" onClick={saveFiles}>
                Ajouter
              </Button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

DropModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  currentFolder: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
};

const MultipleLoading = ({ listUploadFile }) => {
  const styles = {
    container: { margin: "15px" },
    text: { margin: 0 },
  };

  return (
    <>
      {Object.keys(listUploadFile).map((key) => {
        const file = listUploadFile[key];
        return (
          <div key={key} style={styles.container}>
            <p style={styles.text}>{file.name} :</p>
            <ProgressBar
              animated
              now={file.progress}
              label={`${file.progress}%`}
            />
          </div>
        );
      })}
    </>
  );
};

export default DropModal;
