import React, { useState, useEffect } from "react";
import "react-keyed-file-browser/dist/react-keyed-file-browser.css";
import { setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";

import FileEplorer from "../components/FileExplorer";
import Loading from "../components/Loading";
import { authFetch, logout } from "../utils/auth.tsx";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

function RootPage() {
  setChonkyDefaults({ iconComponent: ChonkyIconFA });

  const [fetchData, setFetchData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(false);

  useEffect(() => {
    authFetch(`${process.env.REACT_APP_API_ENDPOINT}/get_from_root/`)
      .then((response) => {
        if (response.status === 401) {
          logout();
        }
        response.json().then((responseJson) => {
          setUser(responseJson[0].user);
          setFetchData(responseJson);
          setLoading(false);
        });
      })
      .catch((response) => {
        logout();
      });
  }, [loading]);

  return (
    <div className="App">
      {fetchData ? (
        <FileEplorer data={fetchData} user={user} setLoading={setLoading} />
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default RootPage;
