import React from 'react'

import { Button, Modal } from 'react-bootstrap'
import { Player } from 'video-react'
import 'video-react/dist/video-react.css'

function VideoModal ({ showModal, setShowModal }) {
  const handleClose = () => setShowModal(false)
  // const handleShow = () => setShowModal(true)
  return (
    <>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Player>
            <source src='https://media.w3.org/2010/05/sintel/trailer_hd.mp4' />
          </Player>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default VideoModal
