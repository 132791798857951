import { defineFileAction, ChonkyIconName } from "chonky";

const uploadFileAction = defineFileAction({
  id: "upload",
  fileFilter: (file) => file.isDir,
  button: {
    name: "Ajouter",
    toolbar: true,
    // contextMenu: true,
    icon: ChonkyIconName.upload,
  },
});

const moveToParentAction = defineFileAction({
  id: "move-to-parent",
  requiresSelection: true,
  hotkeys: ["ctrl+m"],
  fileFilter: (file) => !file.noParent,
  button: {
    name: "Remonter dans le dossier parent",
    // toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.folder,
  },
});

// const delegateFileAction = defineFileAction({
//   id: "delegate",
//   fileFilter: (file) => file.isDir,
//   button: {
//     name: "Delegate",
//     toolbar: true,
//     contextMenu: true,
//     icon: ChonkyIconName.loading
//   }
// });

const createFolderFileAction = defineFileAction({
  id: "create_folder",
  fileFilter: (file) => file.isDir,
  button: {
    name: "Créer dossier",
    toolbar: true,
    contextMenu: false,
    icon: ChonkyIconName.folder,
  },
});

const viewFileAction = defineFileAction({
  id: "view",
  requiresSelection: true,
  fileFilter: (file) => file && !file.isDir,
  button: {
    name: "Voir",
    contextMenu: true,
    icon: ChonkyIconName.file,
  },
});

const copyLinkAction = defineFileAction({
  id: "copy_link",
  requiresSelection: true,
  fileFilter: (file) => file && !file.isDir,
  button: {
    name: "Copier le lien",
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.copy,
  },
});

const DownloadFiles = defineFileAction({
  id: "download_files",
  requiresSelection: true,
  fileFilter: (file) => file && !file.isDir,
  button: {
    name: "Télécharger",
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.download,
  },
});

const deleteFiles = defineFileAction({
  id: "delete_files",
  requiresSelection: true,
  hotkeys: ["delete"],
  // fileFilter: file => file && !file.isDir,
  button: {
    name: "Supprimer",
    toolbar: true,
    contextMenu: true,
    icon: ChonkyIconName.trash,
  },
});
export const customActions = [
  uploadFileAction,
  viewFileAction,
  createFolderFileAction,
  moveToParentAction,
  copyLinkAction,
  DownloadFiles,
  deleteFiles,
];
