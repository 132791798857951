import React from "react";

import { Button, Modal, Form } from "react-bootstrap";
import axios from "axios";

function NewFolderModal({
  showModal,
  setShowModal,
  setCurrentFolder,
  currentFolder,
  setLoading,
}) {
  const handleClose = () => setShowModal(false);
  let folderName = "";
  const changeValue = (e) => {
    folderName = e.target.value;
  };
  const saveFiles = (event) => {
    event.preventDefault();
    // event.stopPropagation();
    if (folderName !== "") {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/create_folder/`,
          {
            current: currentFolder.toString(),
            folderName: folderName,
          },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.REACT_TOKEN_AUTH_KEY).accessToken
              }`,
            },
          }
        )
        .then((response) => {
          setShowModal(false);
          setLoading(true);
        });
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Créer un dossier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="container">
            <Form onSubmit={saveFiles}>
              <Form.Group className="mb-3" controlId="formName">
                <Form.Label>Nom du dossier</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nom"
                  onChange={changeValue}
                />
                <Form.Text className="text-muted" />
              </Form.Group>
            </Form>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button typvariant="primary" onClick={saveFiles}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewFolderModal;
