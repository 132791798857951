import "./Login.css";
import React, { useState } from "react";
import { Navigate } from "react-router";
import { login } from "../utils/auth.tsx";
import axios from "axios";

function LoginPage() {
  const [password, setPassword] = useState("");
  const [isLogin, setIsLogin] = useState(false);
  const [username, setusername] = useState("");
  const [passwordError, setpasswordError] = useState("");

  const handleValidation = () => {
    let formIsValid = true;

    setpasswordError("");

    // setIsLogin(true)
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    axios
      .post(`${process.env.REACT_APP_API_ENDPOINT}/api/token/`, formData)
      .then((response) => {
        if (response.status === 200) {
          login({
            accessToken: response.data.access,
            refreshToken: response.data.refresh,
          });
          setIsLogin(true);
        }
      })
      .catch((error) => {
        console.error(error);
        setpasswordError("Identifiant ou mot de passe incorrect");
      });

    return formIsValid;
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    handleValidation();
  };
  if (isLogin) return <Navigate to="/root" />;

  return (
    <div className="Wrapper">
      <form className="login" onSubmit={loginSubmit}>
        <p>{passwordError}</p>
        <input
          type="text"
          placeholder="Username"
          onChange={(event) => setusername(event.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <button type="submit">Se connecter</button>
      </form>
    </div>
  );
}
export default LoginPage;
