import React from "react";

import { Button, Modal } from "react-bootstrap";
import axios from "axios";

function DeleteModal({
  showModal,
  setShowModal,
  setCurrentFolder,
  currentFolder,
  selectedFiles,
  setLoading,
}) {
  const handleClose = () => setShowModal(false);

  const deleteFile = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/delete_folder/`,
        {
          listeFolder: showModal
            .filter((file) => file.isDir)
            .map((file) => file.id),
          listeFile: showModal
            .filter((file) => !file.isDir)
            .map((file) => file.id.replace(" file", "")),
        },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.REACT_TOKEN_AUTH_KEY).accessToken
            }`,
          },
        }
      )
      .then((response) => {
        setShowModal(false);
        setLoading(true);
      });
  };
  return (
    <>
      <Modal show={true} onHide={handleClose} backdrop="static" keyboard={true}>
        <Modal.Header closeButton>
          <Modal.Title>Supprimer</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes vous sûrs ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annnuler
          </Button>
          <Button variant="primary" onClick={deleteFile}>
            Valider
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteModal;
