// import logo from './logo.svg'
import './App.css'
import React from 'react'

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import LoginPage from './pages/Login'
import Root from './pages/Root'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useAuth } from './utils/auth.tsx'

function App () {
  // const [logged] = tokenUtils.useAuth()
  const [logged, ] = useAuth()
  // console.log(tokenUtils.useAuth())
  // let history = useHistory()
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={logged ? <Navigate to='/root' /> : <LoginPage />}
          />

          {/* {logged && (
          <>
            <Route path='/root' element={<Root />} exact />
            <Navigate from='*' to='/root' />
          </>
        )} */}
          <Route
            path='/root'
            element={!logged ? <Navigate to='/' /> : <Root />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
