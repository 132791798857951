import { ChonkyActions } from "chonky";
import { findFile } from "./folderSearch";
// import fileData from "./data";
import axios from "axios";

const handleAction = (
  data,
  currentFolder,
  setCurrentFolder,
  setShowModal,
  setUploadModal,
  setAddfolderModal,
  setDeleteModal,
  setLoading,
  dataFile
) => {
  if (data.id === ChonkyActions.OpenFiles.id) {
    const file = findFile(dataFile, data.payload.files[0].id);
    if (file?.isDir) {
      setCurrentFolder(file.id);
    }
  } else if (data.id === ChonkyActions.DownloadFiles.id) {
    for (const file of data.state.selectedFiles) {
      axios.get(file.downloadUrl, { responseType: "blob" }).then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        const link = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", file.name); // ou file.name pour donner un nom spécifique
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // Libère l'URL Blob après téléchargement
      });
    }
  } else if (data.id === "view") {
    const file = data.state.selectedFiles[0];
    if (!file?.isDir) {
      // setShowModal(true)
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.setAttribute("href", file.downloadUrl);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      setCurrentFolder(file.id);
    }
  } else if (data.id === "upload") {
    const file = data.state.selectedFiles[0];
    if (!file?.isDir) {
      setUploadModal(true);
    } else {
      setCurrentFolder(file.id);
    }
  } else if (data.id === "create_folder") {
    const file = data.state.selectedFiles[0];
    if (!file?.isDir) {
      setAddfolderModal(true);
    }
  } else if (data.id === "delete_files") {
    const file = data.state.selectedFiles;
    setDeleteModal(file);
  } else if (data.id === "copy_link") {
    const file = data.state.selectedFiles[0];
    navigator.clipboard.writeText(file.downloadUrl);
  } else if (data.id === "move-to-parent") {
    const selection = data.state.selectedFiles;

    const moveFiles = () => {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/move_to_parent_files/`,
          {
            listeFolder: selection
              .filter((file) => file.isDir)
              .map((file) => file.id),
            listeFile: selection
              .filter((file) => !file.isDir)
              .map((file) => file.id.replace(" file", "")),
            destination: currentFolder,
          },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.REACT_TOKEN_AUTH_KEY).accessToken
              }`,
            },
          }
        )
        .then((response) => {
          setShowModal(false);
          setLoading(true);
        });
    };
    moveFiles();
  } else if (data.id === "end_drag_n_drop") {
    const selection = data.payload.selectedFiles;

    const moveFiles = () => {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/move_files/`,
          {
            listeFolder: selection
              .filter((file) => file.isDir)
              .map((file) => file.id),
            listeFile: selection
              .filter((file) => !file.isDir)
              .map((file) => file.id.replace(" file", "")),
            destination: data.payload.destination.id,
          },
          {
            headers: {
              Authorization: `Bearer ${
                JSON.parse(localStorage.REACT_TOKEN_AUTH_KEY).accessToken
              }`,
            },
          }
        )
        .then((response) => {
          setShowModal(false);
          setLoading(true);
        });
    };

    moveFiles();
  }
};

export default handleAction;
