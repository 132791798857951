import React, { useState, useEffect } from "react";
import "react-keyed-file-browser/dist/react-keyed-file-browser.css";
import { setChonkyDefaults, FullFileBrowser, ChonkyActions } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import VideoModal from "../modals/VideoModal";
import DropModal from "../modals/DropModal";
import NewFolderModal from "../modals/NewFolderModal";
import DeleteModal from "../modals/DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import folderSearch from "../../utils/folderSearch";
import handleAction from "../../utils/chonkyActionHandler";
import { customActions } from "../../utils/chonkyCustomActions";
import { Navbar, Container, Button } from "react-bootstrap";
import { logout } from "../../utils/auth.tsx";
import "./styles/main.css";

setChonkyDefaults({ iconComponent: ChonkyIconFA });

function FileEplorer(props) {
  // setChonkyDefaults({ iconComponent: ChonkyIconFA })

  const [currentFolder, setCurrentFolder] = useState("1");
  const [files, setFiles] = useState(null);
  const [folderChain, setFolderChain] = useState(null);
  const fileActions = [
    ...customActions,
    ChonkyActions.EnableGridView,
    ChonkyActions.EnableListView,
  ];
  const [showVideoModal, setVideoModal] = useState(false);
  const [showUploadModal, setUploadModal] = useState(false);
  const [showAddFolderModal, setAddfolderModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  let selectedFile = [];

  const handleActionWrapper = (data) => {
    selectedFile = data.state.selectedFile;
    handleAction(
      data,
      currentFolder,
      setCurrentFolder,
      setVideoModal,
      setUploadModal,
      setAddfolderModal,
      setDeleteModal,
      props.setLoading,
      props.data
    );
  };

  useEffect(() => {
    let folderChainTemp = [];
    let filesTemp = [];

    const [found, filesTemp1, folderChainTemp1] = folderSearch(
      props.data,
      folderChainTemp,
      currentFolder
    );

    if (found) {
      filesTemp = filesTemp1;
      folderChainTemp = folderChainTemp1;
    }

    setFolderChain(folderChainTemp);
    setFiles(filesTemp);
  }, [currentFolder, props.data]);

  const thumbnailGenerator = (file) => {
    if (
      file.name.endsWith(".png") ||
      file.name.endsWith(".jpeg") ||
      file.name.endsWith(".jpeg")
    ) {
      return file.downloadUrl;
    }
  };

  const logoutUser = () => {
    logout();
  };
  const i18n = {
    locale: "fr",

    messages: {
      // Chonky UI translation strings. String IDs hardcoded into Chonky's source code.
      "chonky.toolbar.searchPlaceholder": "Rechercher",
      "chonky.actions.view.button.name": "voir",
      "chonky.toolbar.visibleFileCount": `{fileCount, plural,
          =0 {# elements}
          one {# element}
          other {# elements}
      }`,
      "chonky.toolbar.selectedFileCount": `{fileCount, plural,
             =0 {}
            one {# fichiers}
            other {# fichiers}
        }`,
      "chonky.toolbar.hiddenFileCount": `{fileCount, plural,
            =0 {}
            one {# скрыт}
            other {# скрыто}
        }`,
      "chonky.fileList.nothingToShow": "Ce dossier est vide",
      "chonky.actions.move-to-parent.button.name":
        "Remonter dans le dossier parent",
      "chonky.actions.enable_grid_view.button.name": "Vue grille",
      "chonky.actions.enable_list_view.button.name": "Vue liste",
      "chonky.actions.delete_files.button.name": "Supprimer",
      "chonky.actions.create_folder.button.name": "Créer dossier",
      "chonky.actions.open_parent_folder.button.name": "Ouvrir parent",
      "chonky.actions.upload.button.name": "Ajouter",
      "chonky.actions.download_files.button.name": "Télécharger",
      "chonky.contextMenu.browserMenuShortcut":
        "menu du navigateur: {shortcut}",
      // File action translation strings. These depend on which actions you have
      // enabled in Chonky.
      [`chonky.actionGroups.Actions`]: "Actions",
      [`chonky.actionGroups.Options`]: "Options",
      [`chonky.actionGroups.BrowserMenu`]: "Options",
    },
  };

  return (
    <>
      {/* <h1>Communication</h1> */}
      <Navbar bg="light" expand="lg">
        <Container className="d-flex" fluid>
          <Navbar.Brand href="#">Parole de vie</Navbar.Brand>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text style={{ paddingRight: 10 }}>{props.user}</Navbar.Text>
            <Button onClick={logoutUser} variant="outline-danger">
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <FullFileBrowser
        files={files}
        thumbnailGenerator={thumbnailGenerator}
        folderChain={folderChain}
        defaultFileViewActionId={ChonkyActions.EnableGridView.id}
        fileActions={fileActions}
        // darkMode={true}
        i18n={i18n}
        onFileAction={handleActionWrapper}
        disableDefaultFileActions={true}
      />
      {showVideoModal && (
        <VideoModal showModal={showVideoModal} setShowModal={setVideoModal} />
      )}
      {showUploadModal && (
        <DropModal
          showModal={showUploadModal}
          setShowModal={setUploadModal}
          currentFolder={currentFolder}
          setLoading={props.setLoading}
        />
      )}
      {showAddFolderModal && (
        <NewFolderModal
          showModal={showAddFolderModal}
          setShowModal={setAddfolderModal}
          setCurrentFolder={setCurrentFolder}
          currentFolder={currentFolder}
          setLoading={props.setLoading}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setDeleteModal}
          setCurrentFolder={setCurrentFolder}
          currentFolder={currentFolder}
          selectedFiles={selectedFile}
          setLoading={props.setLoading}
        />
      )}
    </>
  );
}

export default FileEplorer;
