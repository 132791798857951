const folderSearch = (data1, folderChainTemp, currentFolder) => {
  const filesTemp = [];
  for (let i = 0; i < data1.length; i++) {
    const folder = data1[i];
    folderChainTemp = [
      ...folderChainTemp,
      { id: folder.id, name: folder.name },
    ];
    if (folder.id === currentFolder) {
      if (folder?.files) {
        folder.files.forEach((file) => {
          filesTemp.push({
            id: file.id,
            name: file.name,
            isDir: file.isDir,
            noParent: file.noParent,
            thumbnailUrl: file.thumbnailUrl,
            downloadUrl: file.downloadUrl,
          });
        });
      }
      return [true, filesTemp, folderChainTemp];
    } else if (folder?.files) {
      const returnValues = folderSearch(
        folder.files,
        folderChainTemp,
        currentFolder
      );
      if (returnValues[0]) {
        return returnValues;
      }
    }
    folderChainTemp = folderChainTemp.slice(0, folderChainTemp.length - 1);
  }
  return [0, null, null];
};

export const findFile = (data, fileId) => {
  for (let i = 0; i < data.length; i++) {
    const folder = data[i];
    if (folder.id === fileId) {
      return folder;
    } else if (folder?.files) {
      const returnValues = findFile(folder.files, fileId);
      if (returnValues) {
        return returnValues;
      }
    }
  }
  return null;
};

export default folderSearch;
